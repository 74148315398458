<template>
  <div id="mArticle" class="article_list">
    <div class="head_section">
      <h2 id="dkBody" class="tit_section">가이드 관리</h2>
    </div>
    <ul class="list_guideline">
      <li v-for="(useGuide, idx) in useGuideList" :key="idx" class="guideline_item">
        <!--<div class="txt_code">
          <span class="screen_out">코드명</span>{{useGuide.subject}}
        </div>-->
        <strong class="tit_guide">{{ useGuide.title }}<br />안내 가이드</strong>
        <div class="info_modify">
          <span class="txt_date"> {{ useGuide.regDate | strDateTimeSplit }}</span>
          <button class="btn_small btn_secondary" @click.prevent="moveEdit(useGuide.guideNo)">
            수정
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";
import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";
import { dateToStringWithDiv, getDateString } from "@/utils/dateUtils";

export default {
  name: "UseGuideList",
  data() {
    return {
      useGuideList: [],
      guideItemWidth: 0,
      contentPadding: 80,
      guidItemPadding: 15,
    };
  },
  created() {
    this.getGuide();
  },
  methods: {
    async getGuide() {
      const path = `${this.$apiPath.APRVL_GUIDE}?page=1&size=10`;
      const result = await ApiService.shared.getData(path);

      if (!result.data) return;

      this.guideContents = result.data[0].contents;
      this.useGuideList = result.data;
    },
    moveEdit(guideId) {
      const nextPath =
        this.$route.meta.menuId === "purchaseRequestDraft"
          ? "purchaseUseGuideEdit"
          : "useGuideEdit";
      this.$router.push({ name: nextPath, query: { guideId: guideId } });
    },
  },
};
</script>

<style scoped>
/* 가이드 관리 */
.list_guideline {
  padding: 34px 38px 57px 58px;
  border-top: 2px solid #222;
  border-bottom: 1px solid #e1e1e1;
  background-color: #fbfbfb;
}
.list_guideline:after {
  display: block;
  clear: both;
  content: "";
}
.list_guideline li {
  float: left;
  width: 236px;
  margin: 24px 20px 0 0;
  padding: 24px 20px 20px;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  box-sizing: border-box;
}
/* .list_guideline li:nth-child(5n){margin-right:0} */
.list_guideline .tit_guide {
  display: block;
  height: 132px;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: #222;
}
.list_guideline .info_modify {
  overflow: hidden;
}
.list_guideline .txt_date {
  float: left;
  padding-top: 7px;
  font-size: 13px;
  line-height: 19px;
  color: #555;
}
.list_guideline button {
  float: right;
}
/* ;width:60px;height:36px;border:1px solid #D9D9D9;border-radius:3px;font-size:13px;line-height:34px;color:#3c3c3c;box-sizing:border-box;text-decoration:none;text-align:center */
/* .list_guideline .link_modify:hover{border-color:#EFEFEF;background:#EFEFEF} */
</style>
